import React, {useEffect, useState} from 'react'

const Terms = () => {
    const [description, setDescription] = useState('')

    useEffect(() => {
        async function fetchData () {
            await fetch('https://s3-sa-east-1.amazonaws.com/vivosaludjproduction/terms.json')
            .then(res => res.json())
            .then(res => {
                setDescription(res.content)
            })
        }

        fetchData()
    });

    return (
        <>
            <div style={{width: '100%', height: '100%', backgroundColor: 'white', padding: 40, fontSize: 16}}>
                <h1>Terminos y Condiciones</h1>
                <div dangerouslySetInnerHTML={{__html: description}}>
                </div>
            </div>
        </>
    )
}

export default Terms