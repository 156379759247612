import React from "react"

import Terms from "../components/Terms"
import Layout from "../components/layout"
import SEO from "../components/seo"

const TermsPage = () => (
    <Layout>
    <SEO
      title="Muvu - La app que te recompensa por hacer actividad física."
      description="Muvu es la app que te premia por hacer actividad física, gana puntos y canjealos por increibles premios."
    />
    <Terms />
  </Layout>
)

export default TermsPage